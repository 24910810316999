import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  // eslint-disable-next-line no-debugger
  // debugger

  // eslint-disable-next-line no-console
  console.log(theme)

  return {
    steps: {
      marginTop: theme.spacing(8),
    },
    stepItemNumber: {
      color: theme.gofasting.steps.item.color,
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '54px',
      textAlign: 'center',
      verticalAlign: 'center',
      borderRadius: '50%',
      background: theme.gofasting.steps.item.background,
      width: '52px',
      height: '52px',
    },
    stepItemText: {
      paddingTop: theme.spacing(1),
      color: theme.gofasting.steps.item.color,
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '28px',
      [theme.breakpoints.up('md')]: {
        fontSize: '25px',
        lineHeight: '35px',
      },
      '& span': {
        padding: '1px 8px',
        fontWeight: '600',
        backgroundColor: '#FFD600',
        color: '#1B1F2A',
        borderRadius: '6px',
      },
    },
    arrow: {
      width: '25px',
      height: '40px',
      borderRightColor: theme.gofasting.steps.line.color,
      borderRightWidth: '2px',
      borderRightStyle: 'solid',
    },
  }
})

function Steps() {
  const classes = useStyles()

  return (
    <Grid container className={classes.steps}>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
        >
          <Grid item xs={3} sm={2} lg={1} xl={1} className={classes.stepItem}>
            <Typography className={classes.stepItemNumber}>1</Typography>
            <div className={classes.arrow} />
          </Grid>
          <Grid item xs={9} sm={5} lg={3} xl={2}>
            <Typography className={classes.stepItemText}>
              <span>Choose</span>
              {' '}
              the most appropriate fasting plan
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
        >
          <Grid item xs={3} sm={2} lg={1} xl={1} className={classes.stepItem}>
            <Typography className={classes.stepItemNumber}>2</Typography>
            <div className={classes.arrow} />
          </Grid>
          <Grid item xs={9} sm={5} lg={3} xl={2}>
            <Typography className={classes.stepItemText}>
              <span>Get</span>
              {' '}
              personalized meal plan
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
        >
          <Grid item xs={3} sm={2} lg={1} xl={1} className={classes.stepItem}>
            <Typography className={classes.stepItemNumber}>3</Typography>
            <div className={classes.arrow} />
          </Grid>
          <Grid item xs={9} sm={5} lg={3} xl={2}>
            <Typography className={classes.stepItemText}>
              <span>Enjoy</span>
              {' '}
              visible results
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
        >
          <Grid item xs={3} sm={2} lg={1} xl={1} className={classes.stepItem}>
            <Typography className={classes.stepItemNumber}>4</Typography>
          </Grid>
          <Grid item xs={9} sm={5} lg={3} xl={2}>
            <Typography className={classes.stepItemText}>
              <span>Make</span>
              {' '}
              your life healthier
            </Typography>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}

export default Steps
