/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Container, Grid, Typography, Hidden,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from './ButtonHead'
import Logo from './Logo'

import BgTopImage from '../static/images/headtop.jpg'
import BgBottomImage from '../static/images/headbottom.png'

const useStyles = makeStyles((theme) => ({
  container: {
    background: `url(${BgTopImage}) no-repeat top center`,
    position: 'relative',
    backgroundSize: '100%',
    [theme.breakpoints.up('xs')]: {
      height: theme.spacing(70),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(120),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(165),
    },
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(120),
    },
  },
  logo: {
    marginTop: theme.spacing(5),
  },
  textContainer: {
    // marginTop: theme.spacing(15),
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(38),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(50),
    },
  },
  text: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '36px',
    fontWeight: '700',
    '& span': {
      color: '#FFD600',
    },
  },
  button: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(7),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(15),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(30),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(20),
    },
  },
  shadowTop: {
    position: 'absolute',
    width: '100%',
    height: '137px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0) 100%)',
    zIndex: '1',
  },
  shadowBottom: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '137px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)',
    zIndex: '3',
  },
  bgBottom: {
    background: `url(${BgBottomImage}) no-repeat top center`,
    position: 'absolute',
    bottom: '0',
    backgroundSize: '100%',
    [theme.breakpoints.up('xs')]: {
      height: theme.spacing(40),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(68),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(90),
    },
    width: '100%',
    zIndex: '2',
  },
  content: {
    height: '100%',
    position: 'relative',
    zIndex: '4',
  },
}))

function Head({ link }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.shadowTop} />
      <div className={classes.shadowBottom} />

      <Hidden lgUp>
        <div className={classes.bgBottom} />
      </Hidden>

      <Grid
        container
        justifyContent="center"
        className={classes.content}
      >
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            justifyContent="center"
          >
            <Grid item xs={12} className={classes.logo}>
              <Logo link={link} />
            </Grid>

            <Grid item xs={8} className={classes.textContainer}>
              <Typography className={classes.text} variant="h3">
                {' '}
                No matter
                {' '}
                <span>WHAT</span>
                {' '}
                you eat,  it matters
                {' '}
                <span>WHEN</span>
                {' '}
                you eat
              </Typography>
            </Grid>

            <Grid
              item
              xs={10}
              sm={8}
              md={6}
              className={classes.button}
            >
              <Button href={link}>Try For Free</Button>
            </Grid>

          </Grid>
        </Grid>
      </Grid>

    </div>
  )
}

Head.propTypes = {
  link: PropTypes.string.isRequired,
}

export default Head
